<template>
    <NuxtLink
        no-prefetch
        :to="path + info.slug"
        rel="follow"
        class="chip-item"
        :class="[
            isActive ? 'border-site-primary' : 'border-gray-200 hover:border-gray-300',
            $route.path.includes('/' + $lang.routes.brands) ? 'store' : '',
        ]"
    >
        <div
            v-if="info.image && info.image.url"
            class="chip-item_icon"
            :class="$route.path.includes('/' + $lang.routes.brands) ? 'store' : ''"
        >
            <img
                :src="info.image.url"
                :alt="info.name"
                :title="info.name"
                loading="lazy"
                :onerror="`this.onerror=null;this.src='${path === '/' + $lang.routes.categories ? defaultImage.category : defaultImage.store}'`"
            />
        </div>
        <p
            class="chip-item_name"
            :class="isActive ? 'text-site-primary underline underline-offset-2' : 'text-gray-800'"
        >
            {{ info.name }}
        </p>
    </NuxtLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'ChipCategory',
    props: {
        info: {
            type: Object,
            required: true,
        },
        path: {
            type: String,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return { defaultImage: defaultImages }
    },
    computed: {
        isActive(): boolean {
            return this.info.slug === this.$route.params.slug
        },
    },
})
</script>

<style lang="postcss" scoped>
.chip-item {
    @apply flex h-16 cursor-pointer items-center space-x-3 rounded-lg border bg-white px-3 py-2;

    &.store {
        @apply h-auto flex-col gap-y-3;
    }

    &_name {
        @apply flex h-10 items-center;
    }

    &_icon {
        @apply h-6 w-6;

        img {
            @apply h-full w-full object-contain;
        }
        &.store {
            @apply size-[100px];
        }
    }
}
</style>
