export const useStoreValuation = (id: number) => {
    const valuationArrayKey = 'site-store-valuation-storage'

    const storedValuations: Ref<number[]> = ref(JSON.parse(localStorage.getItem(valuationArrayKey) || '[]'))

    const isValuated = computed(() => storedValuations.value.includes(id))

    const valuate = () => {
        if (!isValuated.value) {
            storedValuations.value = [...storedValuations.value, id]

            const serializedValuations = JSON.stringify(storedValuations.value)

            console.log(serializedValuations)

            localStorage.setItem(valuationArrayKey, serializedValuations)
        }
    }

    return {
        valuate,
        isValuated,
    }
}
