<template>
    <section class="faq-dropdown-block">
        <h3 class="faq-dropdown-block_head">
            <img :src="$assets.primary.questionMaletin" alt="icon" width="32px" height="32px" />
            <span>{{ $lang.components.cardFaq.frequent_questions }}</span>
        </h3>
        <div class="faq-dropdown-block_body">
            <ul id="questions">
                <li v-for="({ question, answer }, key) in questions" :id="'store-question-' + key" :key="key">
                    <div class="title">
                        {{ question }}
                    </div>
                    <div>
                        <p>{{ answer }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardFaq',
    props: {
        title: {
            type: String,
            default: 'Question',
        },
        icon: {
            type: String,
            default: 'Question',
        },
        toggle: Object as PropType<{
            index: number | null
            value: boolean
        }>,
        questions: {
            type: Array as PropType<Models.Faq[]>,
        },
    },
})
</script>

<style lang="postcss" scoped>
.faq-dropdown-block {
    &_head {
        @apply mb-2 flex items-center space-x-2 text-lg font-semibold text-site-primary;
        img {
            @apply h-5 flex-none;
        }
    }
    &_body {
        ul {
            @apply rounded-xl border bg-white p-2;
            li {
                @apply px-3 first:pt-3 last:pb-3;

                .title {
                    @apply flex w-full items-center justify-between border bg-site-primary bg-opacity-10 p-3 text-left text-lg font-bold text-gray-800;
                }

                p {
                    @apply border border-t-0 p-3 text-gray-800;
                }
            }

            li:first-child .title {
                @apply rounded-[10px_10px_0_0];
            }

            li:last-child p {
                @apply rounded-[0_0_10px_10px];
            }
        }
    }
}
</style>
