<template>
    <article class="new-no-results-store">
        <p class="new-no-results-store__description">{{ description }}</p>
        <section class="new-no-results-store__section">
            <ul class="space-y-3">
                <li v-for="item in info.page.data.discounts.slice(0, 10)" :key="item.title + item.id">
                    <DelayHydration>
                        <DCardAdmin :info="item" />
                    </DelayHydration>
                </li>
            </ul>
        </section>
    </article>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'
import { replacePlaceholders } from '~/util/textFunction'

const props = defineProps({
    storeName: {
        type: String,
        required: true,
    },
})

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('top-25', () => {
    return $fetch<Api.Responses.Pages.top25>(endpoints.pages.top25.path, {
        headers: buildHeaders(),
        baseURL: baseUrl,
        method: 'GET',
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let info = responseData.value as Api.Responses.Pages.top25

const [description] = replacePlaceholders(
    [[':store', props.storeName || '']],
    ['No tenemos cupones ni ofertas para :store pero te recomendamos estos cupones:'],
)
</script>

<style lang="postcss" scoped>
.new-no-results-store {
    &__description {
        @apply my-5 text-center text-base md:text-lg lg:text-xl;
    }
    &__section {
        @apply mx-auto max-w-5xl;
    }
}
</style>
